<template>
  <v-dialog
    :value="value"
    @input="$emit('update:value', $event)"
    scrollable
    persistent
    :max-width="maxWidth"
  >
    <v-card>
      <v-card-title style="display: block" class="pb-1">
        <v-icon v-if="actionButtonText == 'Salvar'" x-large class="mr-3">mdi-square-edit-outline</v-icon>
        <span class="dialog-title">{{ title }}</span>
        <v-btn
          @click="$emit('update:value', false);$emit('cancel')"
          icon
          style="position: absolute; right: 5px; top: 5px"
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="value" class="pb-0">
        <slot></slot>
      </v-card-text>
      <v-divider v-if="value && !hideActions"></v-divider>
      <v-card-actions v-if="value && !hideActions" class="px-6 pb-3 pt-0">
        <v-spacer></v-spacer>

        <template v-if="value.type == 'alert'">
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="
              $emit('update:value', false);
              $emit('confirm');
            "
          >
            OK
          </v-btn>
        </template>
        <template v-else-if="value.type == 'confirm'">
          <v-btn
            color="secondary"
            class="pxF-5"
            dark
            depressed
            @click="$emit('update:value', false);$emit('cancel')"
          >
            Não
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="
              $emit('update:value', false);
              $emit('confirm');
            "
          >
            Sim
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            v-if="actionButtonText == 'Salvar'"
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="$emit('update:value', false);$emit('cancel')"
          >
            <v-icon left>mdi-chevron-left</v-icon>Cancelar
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            class="pxF-5"
            dark
            depressed
            @click="$emit('update:value', false);$emit('cancel')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="
              $emit('update:value', false);
              $emit('confirm');
            "
          >
            {{ actionButtonText }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: { type: String },
    actionButtonText: {
      type: String,
      default: "Confirmar",
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [Number, String],
      default: 500,
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog-title {
  word-break: keep-all;
}
</style>
